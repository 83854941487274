import React, { useEffect, useRef, useState } from "react";
import OrderCardView from "../../components/orderCardView";
import OrderCollapsibleCard from "../../components/orderlistView";
import { Container, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import { getCurrentOrder } from "../../hooks/apiHandler";
import { countOrdersByType } from "../../hooks/commanFunc";

export const Order = () => {
    const { Logout } = useAuth();
    var storeID = useRef(JSON.parse(window.localStorage.getItem('ds')));
    const [orderData, setOrderData] = useState();
    const [displayOrder, setDisplayOrder] = useState();

    const [reloadFlag, setReloadFlag] = useState(false);
    const [cdata, setcData] = useState({
        currentOrder: 0,
        inStore: 0,
        online: 0,
        partners: 0,
        completed: 0,
        storeOpen: 'Loading...',
    });
    const [listOrderType, selectOrderType] = useState('All');
    //const { data, loading } = ApiPostH('/currentOrder', { storeID: JSON.parse(window.localStorage.getItem('ds')) });

    useEffect(() => {
        async function callOrderData() {
            var response = await getCurrentOrder({ storeID: storeID.current }, Logout);
            setOrderData(await response.data);
        }
        callOrderData()
        setReloadFlag(false);
        if (orderData) {
            console.log(orderData);
            console.log(orderData.completedOrders.length);
            var data = countOrdersByType(orderData.currentOrders)
            console.log(data.patners)
            setcData({
                ...cdata,
                completed: orderData.completedOrders.length,
                currentOrder: orderData.currentOrders.length,
                inStore: data["in-store"],
                online: data["online"],
                partners: data["patners"],
            })
        }

    }, [reloadFlag, Logout]);

    const handleOrderDisplay = (e) => {
        console.log(e.target.value);
        selectOrderType(e.target.value);
    }

    return (
        <Container>
            <Grid
                container
                spacing={2}
                justifyContent="space-between"
            >
                <Grid item xs={6} m={5} lg={5} xl={5}>
                    Daily Order Dashboard
                </Grid>
                <Grid item xs={6} m={4} lg={4} xl={4}>
                    Store Open : {cdata.storeOpen}
                </Grid>
            </Grid>
            <div>
                <OrderCardView {...cdata} />
                {/* <OrderCollapsibleCard key={} title={} orders={}/> */}
            </div>
            <span style={{ marginTop: 15 }}></span>
            <Grid container
                justifyContent="space-between" 
                alignItems={"center"}
                >
                <Grid item xs={6} m={6} lg={5} xl={5} margin={0}>Orders</Grid>
                <Grid item xs={6} m={6} lg={5} xl={5} margin={0} textAlign={"end"}> 
                    <FormControl sx={{ m: 1, minWidth: 130 }}>
                    <InputLabel id="order-select-autowidth-label">Order Type</InputLabel>
                    <Select
                        labelId="order-select-autowidth-label"
                        id="order-display-dropdown"
                        value={listOrderType}
                        onChange={handleOrderDisplay}
                        autoWidth
                        label="Order Type"
                    >
                        <MenuItem value="All" selected>
                            All
                        </MenuItem>
                        <MenuItem value={20}>Current Orders</MenuItem>
                        <MenuItem value={21}>Completed Orders</MenuItem>
                        <MenuItem value={22}>Cancel Order</MenuItem>
                    </Select>
                </FormControl></Grid>
            </Grid>
            <hr />
            {(orderData) ? (
                orderData.currentOrders.map((item, pindex) => (
                    <OrderCollapsibleCard key={`cca-${pindex}`} title={item.table} orders={item.orders} />
                ))
            ) : (<p>Loading.....</p>)
                // loading ? () : (
                //     data.data.map((item, pindex) => (
                //         <OrderCollapsibleCard key={`cca-${pindex}`} title={item.table} orders={item.orders} />
                //     ))
                //     // console.log(data)
                // )
            }
        </Container >
    );
};
