import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import StoreIcon from '@mui/icons-material/Store';
import ListItem from '@mui/material/ListItem';
import { Avatar, Badge, FormControl, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, useMediaQuery } from '@mui/material';
import { useNavigate, useOutlet } from "react-router-dom";
import { useAuth } from '../hooks/useAuth';

import Button from '@mui/material/Button';
import { BarChart, Create, Dashboard, Event, PowerSettingsNew, Public, RestaurantMenu, Settings, ShoppingCart, ViewQuilt } from '@mui/icons-material';
import { ListDrawerItem } from './ListDrawerItem';
import { useLocalStorage } from '../hooks/useLocalStorage';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export const MiniDrawer = () => {
  const { user, Logout, listStore, updateStoreList } = useAuth();
  const [da, setda] = useLocalStorage('da', null);
  const [sstore, setStore] = useLocalStorage('ds', 'loading');

  const [open, setOpen] = useState(true);

  const theme = useTheme();
  const isSmallerThanXL = useMediaQuery(theme.breakpoints.down('xl'));
  const outlet = useOutlet();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
      return;
    }
    if (isSmallerThanXL) {
      setOpen(false);
    }
      updateStoreList();
  }, [isSmallerThanXL, user, navigate]);

  const handleStoreChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === '-1') {
      setda(null);
      setStore('loading');
      navigate("/store", { replace: true })
    } else {
      const foundStore = listStore.find(store => store.storeid === selectedValue);
      if (foundStore) {
        setda(foundStore.type);
        setStore(foundStore.storeid);
      }
      if (da === 0) {
        navigate("/admin", { replace: true });
      } else if (da === 1) {
        navigate("/manager", { replace: true });
      }
    }
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Admin
            </Typography>
            {/* Enter here code for store select and notification... */}
            <Box sx={{ flexGrow: 1 }} />
            <Box key={'m-store'} sx={{ display: 'flex' }}>
              <FormControl sx={{ m: 1, minWidth: 150 }} size='small'>
                <Select
                  variant='outlined'
                  style={{ backgroundColor: 'whitesmoke', width: '100%' }}
                  autoWidth
                  onChange={handleStoreChange}
                  value={sstore}  // this should be da Value
                >
                  {
                    listStore.length > 0 ? (
                      listStore.map((store) => (
                        <MenuItem key={'dd-' + store.storeid} value={store.storeid}>
                          <StoreIcon sx={{ mr: 1 }} fontSize="small" />
                          {store.type} - {store.name}
                        </MenuItem>
                      )).concat(<Divider key={'mindrstr-1'} />).concat(
                        <MenuItem key={'dd-default'} value="-1"
                        >
                          <StoreIcon sx={{ mr: 1 }} fontSize="small" />
                          All Stores.
                        </MenuItem>
                      )
                    ) : (
                      <MenuItem key={'dd-Loading'} disabled value="loading">
                        Loading......
                      </MenuItem>
                    )
                  }

                </Select>
              </FormControl>
            </Box>
            <Box key={'m-not'} sx={{ display: 'flex' }} >
              <MenuItem>
                <IconButton
                  size="large"
                  aria-label="Notifications"
                  color="inherit"
                >
                  <Badge badgeContent={17} color="error" >
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </MenuItem>
              <IconButton sx={{ p: 0 }}>
                <Avatar alt="User Name" src="/logo192.png" />
              </IconButton>
            </Box>

          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider  key={'mindr-1'}/>
          <List>
            <ListDrawerItem title={'Dashboard'} action={"/admin/"} icon={<Dashboard />} mode={open} />
            <ListDrawerItem title={'Orders'} action={"/admin/order"} icon={<ShoppingCart />} mode={open} />
            <ListDrawerItem title={'Reservation'} action={"/admin/reservation"} icon={<Event />} mode={open} />
            <ListDrawerItem title={'Menu'} action={"/admin/menu"} icon={<RestaurantMenu />} mode={open} />
            {/* </List>
          <Divider />
          <List> */}
            <ListDrawerItem title={'Layout'} action={"/admin/layout"} icon={<ViewQuilt />} mode={open} />
            <ListDrawerItem title={'Online'} action={"/admin/online"} icon={<Public />} mode={open} disable={true} />
            <ListDrawerItem title={'Reports'} action={"/admin/reports"} icon={<BarChart />} mode={open} />
            <ListDrawerItem title={'Settings'} action={"/admin/settings"} icon={<Settings />} mode={open} />
            <Divider key={'mindr-2'} />
            {/* Following is for temporary... */}
            <ListDrawerItem title={'create Order'} action={"/admin/create"} icon={<Create />} mode={open} />
          </List>
          <Box sx={{ flexGrow: 1, flexDirection: 'row', }}></Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            mb: 2
          }}>
            <List>
            <ListItem key={'Logout-0'} onClick={() => Logout()} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <PowerSettingsNew color="error" />
                </ListItemIcon>
                <ListItemText primary={<Button variant="outlined" color="error" >
                  Logout
                </Button>} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            </List>
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {outlet}
        </Box>
      </Box>
    </div >
  );
}
