import React, { useState } from 'react';
import {
    Grid, Card, CardHeader, CardContent, CardActions, IconButton, Switch,
    Menu, MenuItem
} from '@mui/material';
import { MoreVert, Edit, Delete } from '@mui/icons-material';

const PaymentCard = ({ payment, handleMenuOpen, anchorEl, handleMenuClose, handleEdit, handleRemove }) => (
    <Grid key={'usop-' + payment.name} item xs={2} sm={1} md={1}>
        <Card elevation={5} sx={{ paddingBottom: 0 }}>
            <CardHeader
                title={payment.name}
                action={
                    <IconButton onClick={(event) => handleMenuOpen(event, payment)}>
                        <MoreVert />
                    </IconButton>
                }
            />
            <center>
                <CardContent sx={{ m: 0, p: 0 }}>
                    <img src={payment.image} alt={payment.name} style={{ maxWidth: '100%' }} />
                </CardContent>
            </center>
            <CardActions disableSpacing>
                <Switch checked />
            </CardActions>
        </Card>
    </Grid>
);

const PaymentList = ({ payments, handleEdit, handleRemove }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedPayment, setSelectedPayment] = useState(null);

    const handleMenuOpen = (event, payment) => {
        setAnchorEl(event.currentTarget);
        setSelectedPayment(payment);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedPayment(null);
    };

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={2}
                columns={{ xs: 2, sm: 3, md: 4 }}
            >
                {payments.map(payment => (
                    <PaymentCard
                        key={payment.name}
                        payment={payment}
                        handleMenuOpen={handleMenuOpen}
                        anchorEl={anchorEl}
                        handleMenuClose={handleMenuClose}
                        handleEdit={handleEdit}
                        handleRemove={handleRemove}
                    />
                ))}
            </Grid>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => { handleEdit(selectedPayment); handleMenuClose(); }}>
                    <Edit />&nbsp;Edit
                </MenuItem>
                <MenuItem onClick={() => { handleRemove(selectedPayment); handleMenuClose(); }}>
                    <Delete />&nbsp;Remove
                </MenuItem>
            </Menu>
        </>
    );
};

// Example usage
const Example = () => {
    const [payments, setPayments] = useState([
        { name: 'Payment 1', image: 'https://via.placeholder.com/150' },
        { name: 'Payment 2', image: 'https://via.placeholder.com/150' },
        { name: 'Payment 3', image: 'https://via.placeholder.com/150' },
        { name: 'Payment 4', image: 'https://via.placeholder.com/150' },
        { name: 'Payment 5', image: 'https://via.placeholder.com/150' },
        { name: 'Payment 6', image: 'https://via.placeholder.com/150' },
    ]);

    const handleEdit = (payment) => {
        // handle edit logic
        console.log('Edit:', payment);
    };

    const handleRemove = (payment) => {
        // handle remove logic
        setPayments(payments.filter(p => p.name !== payment.name));
    };

    return (
        <Grid container spacing={2}>
            <PaymentList payments={payments} handleEdit={handleEdit} handleRemove={handleRemove} />
        </Grid>
    );
};

export default Example;
