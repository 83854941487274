import React, { useEffect, useState } from "react";
import {
    Button,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import { RefreshRounded } from "@mui/icons-material";
import ApiPostH from "../../hooks/apiPostH";
import { checkOut, itemRemove, sendOrder } from "../../hooks/apiHandler";

export const CreateOrder = () => {
    const [table, setTable] = useState('');
    const [cart, setCart] = useState([]);
    const [cartTotal, setCartTotal] = useState();
    const [oldCart, setOldCart] = useState([]);

    const { data: menudata, loading: menuloading } = ApiPostH('/listMenu', { storeID: JSON.parse(window.localStorage.getItem('ds')) });
    const { data: cdata, loading: cldata } = ApiPostH('/admin/currentOrder', { storeID: JSON.parse(window.localStorage.getItem('ds')), table: table });
    

    const handleSelectChange = (event) => {
        setTable(event.target.value);
    };

    const addToCart = (product) => {
        const existingProduct = cart.find((item) => item._id === product._id);

        if (existingProduct) {
            setCart(
                cart.map((item) =>
                    item._id === product._id ? { ...item, quantity: item.quantity + 1 } : item
                )
            );
        } else {
            setCart([...cart, { ...product, quantity: 1 }]);
        }
    };

    const removeFromCart = (productId) => {
        setCart(cart.filter((item) => item._id !== productId));
    };
    const removeFromOrder = async (orderIndex, itemIndex) => {
        // call api to remove item from order...
        console.log(orderIndex, itemIndex);
        var jsonPass = {
            storeID: JSON.parse(window.localStorage.getItem('ds')),
            table: table,
            orderIndex: orderIndex,
            itemIndex: itemIndex,
        }
        var resposeOrder = await itemRemove(jsonPass);
        console.log(resposeOrder);
    };

    const calculateTotal = () => {
        return cartTotal;
    };
    const calcTax = () => {
        return (cartTotal * 0.05).toFixed(2);
    };

    const calcTotal = () => {
        return (cartTotal * 1.05).toFixed(2);
    };
    useEffect(() => {
        const total = cart.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
        const oldTotal = oldCart.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
        const combinedTotal = (Number(total) + Number(oldTotal)).toFixed(2);
        setCartTotal(combinedTotal);
        // menufetch();
    }, [cart, oldCart])

    return (
        <Container>
            <FormControl fullWidth>
                <InputLabel id="table-select-label">Table Select</InputLabel>
                <Select
                    labelId="table-select-label"
                    id="table-select"
                    value={table}
                    label="Table"
                    onChange={handleSelectChange}
                >
                    <MenuItem value={1}>Table-1</MenuItem>
                    <MenuItem value={2}>Table-2</MenuItem>
                    <MenuItem value={3}>Table-3</MenuItem>
                    <MenuItem value={4}>Table-4</MenuItem>
                    <MenuItem value={5}>Table-5</MenuItem>
                </Select>
            </FormControl>
            <br />
            <Grid container>
                <Grid item sx={6}>
                    <h6> Create New order for Table: {table}</h6>
                    {console.log(menudata)}
                    {
                        menuloading ? (<p>Loading.....</p>) : (
                            menudata.data.map((item, pindex) => (
                                <div key={`nes-${pindex}`}>
                                    <h4 key={React.key}>{item.catgory}</h4>
                                    <ul key={React.key}>
                                        {item.items.map((nestedItem, index) => (
                                            <li key={`neslis-${index}`} > {nestedItem.item} - {nestedItem.price}
                                                <button onClick={() => {
                                                    addToCart(nestedItem);
                                                }}>Add</button>
                                            </li>))
                                        }
                                    </ul>
                                </div>
                            )))
                    }

                    <Typography>
                        <b>Order List:</b>
                    </Typography>
                    <ul key={'cart'}>
                        {/* this is data from server */}
                        {oldCart.map((item, index) => (
                            <li key={'oca' + index} color="#606060">
                                {item.quantity} x {item.item} - ${(item.price) ? item.price : ''}
                                <button onClick={() => removeFromOrder(item.orderIndex, item.itemIndex)}>Remove</button>
                            </li>
                        ))}
                        {cart.map((item) => (
                            <li key={'cart' + item._id}>
                                {/* {item.item} - ${item.price} (Quantity: {item.quantity}){' '} */}
                                {item.quantity} x {item.item} - ${item.price}
                                <button onClick={() => removeFromCart(item._id)}>Remove</button>
                            </li>
                        ))}
                    </ul>
                    <h5>Sub Total: ${calculateTotal()}</h5>
                    <h5>Tax : ${calcTax()}</h5>
                    <h3>Total: ${calcTotal()}</h3>



                    <Button variant="outlined" color="secondary" onClick={async () => {
                        console.log("Send Order to server for processing")
                        var dataToSend = {
                            storeID: JSON.parse(window.localStorage.getItem('ds')),
                            table: table,
                            type: 0,
                            order: cart,
                            total: calcTotal()
                        }
                        console.log('------cart----');
                        console.log(cart);
                        var response = await sendOrder(dataToSend);
                        console.log(response);
                    }}> Send Order</Button>
                    &nbsp;&nbsp;&nbsp;
                </Grid>
                <Grid item sx={6}>
                    <Button ><RefreshRounded onClick={() => {
                        console.log("----Refresh Button------");
                        console.log(cdata.data[0].orders);
                        // const ordersArray = cdata.data[0].orders;
                        // const combinedOrders = ordersArray.reduce((accumulator, currentOrder) => {
                        //     return accumulator.concat(currentOrder.order);
                        // }, []);
                        const ordersArray = cdata.data[0].orders;

                        const combinedOrders = ordersArray.reduce((accumulator, currentOrder, orderIndex) => {
                            const orders = currentOrder.order.map((order, itemIndex) => ({
                                ...order,
                                orderIndex,
                                itemIndex,
                            }));

                            return accumulator.concat(orders);
                        }, []);

                        setOldCart(combinedOrders);
                        console.log(combinedOrders);


                    }
                    } /></Button>

                    <Button variant="contained" color="primary" onClick={() => {
                        console.log('Final Payment');
                        var dataToSend = {
                            storeID: JSON.parse(window.localStorage.getItem('ds')),
                            table: table,
                            payment: [
                                {
                                    payType: 0, //0: cash
                                    amount: 150,
                                    change: 15
                                },
                                {
                                    payType: 1, //0: creditcard
                                    amount: 150,
                                    payRef: '6581737c0a51e14378065486'
                                }
                            ],
                            cart: cdata.data[0].orders, // this is for final cart Details.
                            total: calculateTotal(),
                            //    change:   //change value if needed... Done...

                        }
                        checkOut(dataToSend);
                    }}>
                        CheckOut
                    </Button>
                </Grid>
            </Grid>

        </Container>
    );
};
