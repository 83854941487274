import { Box, Button, Checkbox, FormControl, FormControlLabel, InputAdornment, MenuItem, Switch, TextField } from "@mui/material";
import React, { useState } from "react";
import { addMenu } from "../hooks/apiHandler";
import ApiPostH from "../hooks/apiPostH";

export const AddMenuForm = () => {
    const [formData, setFormData] = useState({
        storeID: JSON.parse(window.localStorage.getItem('ds')),
        catgory: '',
        newCatgory: '',
        newItem: '',
        price: 0,
        decription: '',
        available: true,
        inStore: false,
        online: false,
        restaurantPatner: false
    });
    const [newCatg, setNewCatg] = useState('none');

    const { catgory, newCatgory, newItem, price, decription, available, inStore, online, restaurantPatner } = formData;

    const handleFormSubmit = async () => {
        console.log('Send this for to server ');
        console.log(formData);
        var response = await addMenu(formData);
        console.log(response);
    }
    // this  api call need to handle properly....
    // calling multiple times
    // user effect...
    const { data, loading } = ApiPostH('/admin/listCatgory', { storeID: JSON.parse(window.localStorage.getItem('ds')) });

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <div>
                <FormControl sx={{ m: 1, width: '52%' }} variant="outlined">
                    <TextField
                        placeholder="Category"
                        select
                        value={catgory}
                        onChange={(e) => {
                            if (e.target.value === '000') {
                                //open new Catgory Dialog.
                                setNewCatg('');
                                setFormData({ ...formData, catgory: e.target.value });
                            } else {
                                setNewCatg('none');
                                setFormData({ ...formData, catgory: e.target.value });
                            }

                        }}
                        id="catg"
                        sx={{ m: 1, width: '100%' }}
                    >
                        {
                            loading ? (
                                <p>
                                Loading....
                                </p>
                            ) : (
                                
                                data.data.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))
                                
                            )
                        }
                        <MenuItem key="new-item" value='000' style={{ color: "coral" }}>
                            + New Category
                        </MenuItem>
                    </TextField>
                </FormControl>
                <FormControl sx={{ m: 1, width: '40%' }} variant="outlined">
                    <TextField
                        placeholder="Add New category"
                        id="newCatg"
                        value={newCatgory}
                        onChange={(e) => setFormData({ ...formData, newCatgory: e.target.value })}
                        sx={{ m: 1, width: '100%', display: newCatg }}
                    />
                </FormControl>
                <br />
                <FormControl sx={{ m: 1, width: '62%' }} variant="outlined">
                    <TextField
                        placeholder="New Item / Dish"
                        id="disHname"
                        sx={{ m: 1, width: '100%' }}
                        value={newItem}
                        onChange={(e) => setFormData({ ...formData, newItem: e.target.value })}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '30%' }} variant="outlined">
                    <TextField
                        placeholder="Price"
                        value={price}
                        onChange={(e) => setFormData({ ...formData, price: e.target.value })}
                        id="price"
                        sx={{ m: 1, width: '100%' }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '95%' }} variant="outlined">
                    <TextField
                        id="Decp"
                        placeholder="Description"
                        value={decription}
                        onChange={(e) => setFormData({ ...formData, decription: e.target.value })}
                        multiline
                        rows={4}
                        sx={{ m: 1, width: '100%' }}
                    />
                </FormControl>
                <FormControlLabel
                    id="avail"
                    control={<Switch color="primary" checked={available}
                        onChange={(e) => setFormData({ ...formData, available: e.target.checked })} />}
                    label="Available"
                    labelPlacement="start"
                />
                <br />
                <Box sx={{ m: 1, width: '100%', pl: 1 }}>
                    <FormControlLabel id="inStore" control={<Checkbox onChange={(e) => setFormData({ ...formData, inStore: e.target.checked })} checked={inStore} />} label="InStore" />
                    <FormControlLabel id="online" control={<Checkbox onChange={(e) => setFormData({ ...formData, online: e.target.checked })} checked={online} />} label="Online" />
                    <FormControlLabel id="restPlat" control={<Checkbox onChange={(e) => setFormData({ ...formData, restaurantPatner: e.target.checked })} checked={restaurantPatner} />} label="Restaurant Partner" />
                </Box>
                <Box sx={{ display: "flex", pt: 5, pl: 2 }} style={{}}>
                    <Button variant="contained" onClick={handleFormSubmit} >Save</Button>
                </Box>
            </div>


        </Box>
    );
}