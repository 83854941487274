import moment from 'moment';

export const renderTime = (date) => {
    let dateTime = moment(Date(date));
    let hour = dateTime.hour();
    let minutes = dateTime.minute();
    return hour + ":" + minutes;
};

export const randomNumber = (length) => {
    let otp = '';
    for (let i = 0; i < length; i++) {
        otp += Math.floor(Math.random() * 10).toString();
    }
    return otp;
}

//export const url = 'https://api.miira.ca/v1';
export const url = 'http://192.168.1.80:1717/v1';

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function countOrdersByType(orders) {
    const orderTypeInStore = "in-store";
    const orderTypePickup = "pickup";
    const orderTypeKiosk = "Kiosk";
    const orderTypeOnline = "online";
    const orderTypePartners = "patners";


    const counts = {
        [orderTypeInStore]: 0,
        [orderTypePickup]: 0,
        [orderTypeKiosk]:0,
        [orderTypeOnline]: 0,
        [orderTypePartners]:0
    };

    orders.forEach(order => {
        switch (order.type) {
            case 0: // Assuming 0 represents 'in-store'
                counts[orderTypeInStore] = (counts[orderTypeInStore] || 0) + 1;
                break;
            case 1: // Assuming 1 represents 'pickup'
                counts[orderTypePickup] = (counts[orderTypePickup] || 0) + 1;
                break;
            case 2: // Assuming 2 represents 'Kiosk'
                counts[orderTypeKiosk] = (counts[orderTypeKiosk] || 0) + 1;
                break;
            case 3: // Assuming 3 represents 'online'
                counts[orderTypeOnline] = (counts[orderTypeOnline] || 0) + 1;
                break;
            default:
                console.warn(`Counting rest partner order type: ${order.type}`);
                counts[orderTypePartners] = (counts[orderTypePartners] || 0) + 1;

        }
    });

    return counts;
}
