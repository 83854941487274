import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Chip, IconButton, ListItem } from "@mui/material"
// import { TableContainer, Paper, TableBody, Table, TableHead, TableRow } from "@mui/material"
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Restaurant, MenuBook, Edit, Delete } from "@mui/icons-material";
import { Container } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Collapse from '@mui/material/Collapse';
import { ModalUnstyled } from "../../modal/Modal";
import { AddMenuForm } from "../../Forms/AddMenuform";

import { getlistMenu } from "../../hooks/apiHandler";

export const MenuPage = () => {

  var [listMenu, setMenuList] = useState([]);
  var storeID = useRef(JSON.parse(window.localStorage.getItem('ds')));

  const [showModal, setshowModal] = useState(false);


  function handleDropDown(index) {
    const updateField = [...listMenu];
    updateField[index].listAct = !(updateField[index].listAct);
    setMenuList(updateField);
  }

  useEffect(() => {
    async function calldeviceList() {
      var response = await getlistMenu({ storeID: storeID.current });
      setMenuList(await response.data);
    }
    calldeviceList()
    return () => {
      // this is same as component unmount of leaving the page..
    }
  }, []);

  return (
    <Container>
      {/* This Following Should be in Admin Panel */}

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="outlined" onClick={() => {
          setshowModal((prev) => !prev);
        }}>+Add Menu</Button>
      </Box>
      <Box sx={{ p: 2 }}></Box>
      {/* End of extra Admin Panel */}

      <List>
        {
          listMenu.length > 0 ? (
            listMenu.map((item, pindex) => (
              <li key={`lis-${pindex}`}>
                <ListItemButton onClick={() => handleDropDown(pindex)}>
                  <ListItemIcon>
                    <MenuBook />
                  </ListItemIcon>
                  <ListItemText primary={item.catgory} autoCapitalize="true" />
                  <Box sx={{ paddingLeft: 5, paddingRight: 5, display: 'flex', alignItems: 'center', gap: 3 }}>
                    <Chip size="small" label={'Items : ' + item.items.length} style={{ borderColor: "#3f51b5", color: "#3f51b5" }} variant="outlined" />
                    {/* <IconButton onClick={() => console.log('Handle Edit')}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={(e) => {
                      console.log('Handle Delete')
                      e.preventDefault();
                    }}>
                      <Delete />
                    </IconButton> */}
                  </Box>
                  {listMenu[pindex].listAct ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={listMenu[pindex].listAct} timeout="auto" unmountOnExit key={`collapse-${pindex}`}>
                  <List>
                    <li key={`nesdefs-${pindex}`} sx={{p:0}}>
                      <ListItem sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                      <ListItemText secondary={'Edit Catgory : '+ item.catgory} autoCapitalize="true" />
                        <Button
                          variant="outlined"
                          color="warning"
                          size="small"
                          startIcon={<Edit />}
                          onClick={() => console.log('Handle Edit')}>
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          size="small"
                          startIcon={<Delete />}
                          onClick={(e) => {
                            console.log('Handle Delete')
                            e.preventDefault();
                          }}>
                          Remove
                        </Button>
                      </ListItem>
                    </li>
                  </List>
                  <List disablePadding>
                    {item.items.map((nestedItem, index) => (
                      <li key={`neslis-${index}`} >
                        <ListItem sx={{ pl: 4 }} key={`nest-${index}`} >
                          <ListItemIcon>
                            <Restaurant />
                          </ListItemIcon>
                          <ListItemText primary={nestedItem.item} secondary={nestedItem.price} />
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                            <IconButton 
                            color="warning"
                            onClick={() => {
                              console.log('Handle Edit');
                              console.log(pindex, index);
                            }}>
                              <Edit />
                            </IconButton>
                            <IconButton
                            color="error"
                             onClick={() => {
                              console.log('Handle Delete');
                              console.log(pindex, index);
                            }}>
                              <Delete />
                            </IconButton>
                          </Box>
                        </ListItem>

                      </li>
                    ))}
                  </List>
                </Collapse>
              </li>
            ))) :
            (<p>Loading.....</p>)
        }

        {/* {listData.map((item, pindex) => (
          <li key={`lis-${pindex}`}>
            <ListItemButton onClick={() => handleDropDown(pindex)}>
              <ListItemIcon>
                <MenuBook />
              </ListItemIcon>
              <ListItemText primary={item.menuName} autoCapitalize="true" />
              {listData[pindex].listAct ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={listData[pindex].listAct} timeout="auto" unmountOnExit key={`collapse-${pindex}`}>
              <List disablePadding>
                {item.items.map((nestedItem, index) => (
                  <ListItemButton sx={{ pl: 4 }} key={`nest-${index}`}>
                    <ListItemIcon>
                      <Restaurant />
                    </ListItemIcon>
                    <ListItemText primary={nestedItem.itemName} secondary={nestedItem.price} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </li>
        ))} */}
      </List>


      <ModalUnstyled
        shouldShow={showModal}
        onRequestClose={() => {
          setshowModal((prev) => !prev);
        }}
      >
        <h2>Add Menu Item</h2>
        <hr />
        <AddMenuForm />
      </ModalUnstyled>


      {/* this is in case of different view... */}
      {/* <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>ID</StyledTableCell>
            <StyledTableCell align="right">Item</StyledTableCell>
            <StyledTableCell align="right">Catagory</StyledTableCell>
            <StyledTableCell align="right">Price</StyledTableCell>
            <StyledTableCell align="right">Available</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.calories}</StyledTableCell>
              <StyledTableCell align="right">{row.fat}</StyledTableCell>
              <StyledTableCell align="right">{row.carbs}</StyledTableCell>
              <StyledTableCell align="right">{row.protein}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> */}
    </Container>
  );
};
