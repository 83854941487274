import { Box, Button, Container, Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { ModalUnstyled } from "../modal/Modal";
import { AddCompnayForm } from "../Forms/AddCompnay";
import { StoreCard } from "../components/StoreCard";
import { useLocalStorage } from "../hooks/useLocalStorage";


export const StoreSelect = () => {
    const { user, listStore, updateStoreList } = useAuth();
    const [showModaladd, setshowModaladd] = useState(false);

    const [da, setda] = useLocalStorage('da', null);
    const [, setStore] = useLocalStorage('ds', null);
    const navigate = useNavigate();
    // this is comming soon
    // const [showModaljoin, setshowModaljoin] = useState(false); 

    /// 0 -> admin.
    /// 1 -> manager.
    /// 2 -> basic user.


    useEffect(() => {
        if (!user) {
            navigate("/login", { replace: true })
        }

        if (da === 0) {
            navigate("/admin", { replace: true })
        } else if (da === 1) {
            navigate("/manager", { replace: true })
        }
        updateStoreList();
    }, [da, user, navigate]);

    const cardActionHandler = async (type, storeid) => {
        setda(type);
        await setStore(storeid);
        if (da === 0) {
            navigate("/admin", { replace: true })
        } else if (da === 1) {
            navigate("/manager", { replace: true })
        }
    }

    return (
        <Container>
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={6} md={8}>
                        <h2>Store List</h2>
                    </Grid>
                    <Grid item xs={6} md={4} justifyItems={"end"}>
                        <Button variant="contained" sx={{ margin: "3px" }}
                            onClick={() => {
                                setshowModaladd((prev) => !prev);
                            }}
                        >+ Add Store</Button>&nbsp;&nbsp;
                        <Button variant="outlined" sx={{ margin: "3px" }} >
                            Join Existing
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Divider sx={{ marginBottom: "15px" }} />
                <Grid container spacing={2} >
                    <>
                        {
                            (listStore.length > 0) ? listStore.map((store) => (<StoreCard key={'ss' + store.storeid} storeName={store.name} storeid={store.storeid} type={store.type} address={store.address} setda={setda} setStore={setStore} cardActionHandler={cardActionHandler} />)) : "Loading"
                        }
                    </>
                </Grid>
            </Box>
            {/* <Box>
                <h3> Manager </h3>
                <Divider sx={{ marginBottom: "15px" }} />
                <Grid container spacing={2}>
                    {
                        array.map((vallue) => (
                            <Grid item key={vallue}>
                                <Card xs={2}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="100"
                                            image=""
                                            alt="green iguana"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                Store Name
                                            </Typography>
                                            <p>Store ID</p>
                                            <Typography variant="body2" color="text.secondary">
                                                Address
                                            </Typography>

                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))
                    }
                </Grid>
            </Box> */}
            <ModalUnstyled
                shouldShow={showModaladd}
                onRequestClose={() => {
                    setshowModaladd((prev) => !prev);
                }}
            >
                <h2>Add New Buisness</h2>
                <hr />
                {/* <AddMenuForm /> */}
                <AddCompnayForm setmodel={setshowModaladd} />
            </ModalUnstyled>
        </Container>
    );
}