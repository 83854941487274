
import React, { useEffect, useState } from "react";
import { Card, CardActions, CardContent, CardHeader, Container, Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, Switch, Typography } from "@mui/material";
import Dollar from './images/Dollar.png';
import Visa from './images/Visa.png';
import Credit from './images/Credit card.png';
import CloseIcon from '@mui/icons-material/Close'
import { AddCircle, MoreVert } from "@mui/icons-material";
import { getDevicePayment } from "../../hooks/apiHandler";



export const PaymentSetting = () => {
    const storeid = JSON.parse(window.localStorage.getItem('ds'));
    const [devicesPayment, setDevicePayment] = useState(
        {
            devices: [],
            PaymentSetting: {}
        }
    );
    const PaymentList = [
        {
            name: 'Cash',
            image: Dollar,
        },
        {
            name: 'Visa',
            image: Visa,
        },
        {
            name: 'Credit Card',
            image: Credit,
        }

    ]

    const [open, openchange] = useState(false);
    const functionopenpopup = () => {
        openchange(true);
    }
    const closepopup = () => {
        openchange(false);
    }
    useEffect(() => {
        async function fetchPaymentData() {
            var response = await getDevicePayment({ storeID: storeid });
            if (await response.data) {
                console.log(response.data.paymentMode.devices);
                setDevicePayment({
                    devices: response.data.paymentMode.devices,
                    paymentSetting: response.data.paymentSetting.paymentSetting
                });
                // set loading false...
            }
        }
        fetchPaymentData();
        console.log(devicesPayment);
        return () => {

        }
    }, [])

    return (
        <Container>
            {
                devicesPayment.devices.length > 0 ? (
                    devicesPayment.devices.map((item, index) => (
                        <Paper elevation={3} style={{ padding: 10, margin:5}}>
                            <Typography variant="h7">{item.name} ({item.hid})</Typography>
                            <hr />
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                spacing={2}
                                columns={{ xs: 2, sm: 3, md: 4 }}
                            >{
                                    PaymentList.map(payment => (
                                        <Grid key={'usop-' + payment.name} item xs={2} sm={1} md={1}>
                                            <Card elevation={2} sx={{ paddingBottom: 0 }}>
                                                <CardHeader
                                                    title={payment.name}
                                                    action={
                                                        <IconButton>
                                                            <MoreVert />
                                                        </IconButton>
                                                    }

                                                />
                                                <center>
                                                    <CardContent sx={{ m: 0, p: 0 }}>
                                                        <img src={payment.image} alt={payment.name}></img>
                                                    </CardContent>
                                                </center>
                                                <CardActions disableSpacing>
                                                    <Switch
                                                        checked
                                                    />
                                                    {/* <Button size="small" variant="outlined" color="info" startIcon={<Edit />}>&nbsp;Edit</Button>
                                    <Button size="small" variant="outlined" color="warning" startIcon={<Delete />}> &nbsp;Remove</Button> */}
                                                </CardActions>
                                            </Card>
                                        </Grid>))
                                }
                                <Grid key={'usop-addPayment'} item xs={2} sm={1} md={1}>
                                    <center>
                                        <IconButton
                                            onClick={functionopenpopup}
                                            size="large"
                                        >
                                            <AddCircle />
                                        </IconButton>
                                    </center>

                                </Grid>
                            </Grid>
                        </Paper>)
                    )
                ) : 'Loading'
            }
            <br />
            <h4>Other Options :</h4>
            <hr />
            <Grid container>
                <Grid item xs={11} md={8}>
                    Tip Option
                </Grid>
                <Grid item xs={1} md={1}>
                    <Switch
                        checked
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={11} md={8}>
                    Show order details to terminal
                </Grid>
                <Grid item xs={1} md={1}>
                    <Switch
                        checked
                    />
                </Grid>
            </Grid>


            <Dialog open={open} onClose={closepopup} fullWidth maxWidth="sm">
                <DialogTitle><IconButton style={{ float: 'right' }}><CloseIcon color='primary' onClick={() => {
                    closepopup()
                }}></CloseIcon></IconButton>
                    <div> <h2 className='Form_title'>Add Payment Option</h2></div>
                </DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-around"
                        alignItems="center"
                        spacing={2}
                        columns={{ xs: 2, sm: 3, md: 3 }}
                    >
                        {
                            PaymentList.map(payment => (
                                <Grid key={'md-' + payment.name} item xs={2} sm={1} md={1}>
                                    <Card elevation={2}>
                                        <CardContent>
                                            <center>
                                                <Typography gutterBottom variant="h6" component="div">
                                                    {payment.name}
                                                </Typography>
                                                <img src={payment.image} alt={payment.name}></img>
                                                <br />
                                            </center>
                                        </CardContent>
                                    </Card>
                                </Grid>))
                        }
                    </Grid>
                </DialogContent>
                {/* <Button onClick={()=>{
  closepopup()
 }} color="error" variant="contained">close</Button> */}
            </Dialog>
        </Container>



    )
}