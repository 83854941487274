import { Notifications } from "@mui/icons-material";
import React from "react";
import { BasicPage } from "../../components/BasicPage";
import Example from "../../temp/example";

export const NotificationSetting = () => {
    return (
        <>
            <BasicPage title="Notification Settings" icon={<Notifications />} />
            <br />
            <Example />
        </>
    )
}