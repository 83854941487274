import React from "react";
import LayoutBuilder from "../../LayoutBuilder/layoutBuilder";
import { Container, Grid, Typography, Box } from '@mui/material';

export const Adash = () => {
  return (
    <div>
      <h6> Admin Dashboard</h6>

      <Container maxWidth={false} sx={{ width: '100%', marginTop: '100px' }}>
        <Grid container spacing={4} display="flex" flexWrap="wrap">
          {/* Left Section */}
          <Grid item xs={12} md={5.5} sx={{ padding: '10px', border: '1px solid #efefef', marginRight: '10px' }}>
            <Typography variant="h5">Sales Overview</Typography>
            <Box>
              <Grid container spacing={4}>
                <Grid item xs={6} md={6}><Typography variant="h5">Annual Sales</Typography><Typography variant="h5">$200,000</Typography></Grid>
                <Grid item xs={6} md={6}><Typography variant="h5">Daily Sales</Typography><Typography variant="h5">$8,000</Typography></Grid>
                <Grid item xs={6} md={6}><Typography variant="h5">Sales Online</Typography><Typography variant="h5">$120,000</Typography></Grid>
                <Grid item xs={6} md={6}><Typography variant="h5">Sales Store</Typography><Typography variant="h5">$80,000</Typography></Grid>
              </Grid>
            </Box>
          </Grid>

          {/* Right Section */}
          <Grid item xs={12} md={5.5} sx={{ padding: '10px', border: '1px solid #efefef', marginLeft: '10px' }}>
            <Typography variant="h5">Orders Overview</Typography>
            <Box>
              <Grid container spacing={4}>
                <Grid item xs={6} md={6}><Typography variant="h5">Total Orders</Typography><Typography variant="h5">1000</Typography></Grid>
                <Grid item xs={6} md={6}><Typography variant="h5">Orders Store</Typography><Typography variant="h5">500</Typography></Grid>
                <Grid item xs={6} md={6}><Typography variant="h5">Orders Online</Typography><Typography variant="h5">500</Typography></Grid>
                <Grid item xs={6} md={6}><Typography variant="h5">View Orders Status</Typography><Typography variant="h5">$80,000</Typography></Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>


    </div>
  );
};
